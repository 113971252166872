<template>
    <div class="wid1200">
        <!-- <div class="back_box" @click="$router.back(-1)"> -->

                    <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>



                <div class="pageTitle"> 集刊全文数据库学术集刊编号申报表 </div>
                <div class="tip" style="margin-top: 20px;">
                    <p>集刊有别于一般图书和丛书，但长久以来难以解决辨识性问题。集刊全文数据库作为国内唯一专注于集刊推广的平台，长期深与知网、万方和南大CSSCI等多家学术传播和评价机构有着深入的合作，并推出集刊编号系统解决辨识、传播和评价过程的问题。
                    </p>
                    <p> 您的集刊申请提交后，将由工作人员人工进行处理，您可通过网站下方“关于我们”中的方式与我们联系。我们也将计划为每一个申请集刊编号的集刊建立主页面，从而更好地宣传贵集刊。</p>
                </div>

                <div class="fstPblMsg" style="margin-top: 10px;">
                    <p>基本信息<span class="tip">说明： 本表格为申请集刊编号使用，“<i style="color:#f56c6c">*</i>”为必填项。</span></p>
                </div>

                <el-row :gutter="20" class="typeMsg">
                    <el-form :model="ruleForm" :validate-on-rule-change="false" :label-position="labelPosition"
                        :rules="rules" ref="ruleForm" label-width="155px" class="demo-ruleForm">
                        <el-form-item label="集刊中文名" prop="titleCn">
                            <el-input class="inputStyle" v-model="ruleForm.titleCn" size="small" placeholder="请输入内容"
                                clearable></el-input>
                        </el-form-item>
                        <el-form-item label="集刊英文名" prop="titleEn">
                            <el-input class="inputStyle" v-model="ruleForm.titleEn" size="small" placeholder="请输入内容"
                                clearable></el-input>
                        </el-form-item>
                        <el-form-item label="集刊封面" prop="imageUrl">
                            <!--<el-col :span="21">-->
                            <div class="itmsg">
                                <el-upload class="avatar-uploader" action="/admin/common/upload" :show-file-list="false"
                                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                    <img v-if="ruleForm.imageUrl" :src="ruleForm.imageUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <span slot="tip" class="el-upload__tip">只能上传PNG/JPG文件</span>
                            </div>
                            <!--</el-col>-->
                        </el-form-item>
                        <el-form-item label="创刊时间" prop="creatDate">
                            <el-date-picker class="inputStyle" v-model="ruleForm.creatDate" size="small" type="date"
                                placeholder="选择日期"> </el-date-picker>
                        </el-form-item>
                        <el-form-item label="出版周期" prop="num">
                            <div class="itmsg">
                                每年
                                <el-select v-model="ruleForm.num" class="numList" size="small" placeholder="请选择">
                                    <el-option v-for="item in numList" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                                辑

                            </div>
                        </el-form-item>
                        <el-form-item label="出版篇幅">
                            <div class="itmsg">
                                每期
                                <el-form-item class="noLeft" prop="articleNum" style="display: inline-block;">
                                    <el-select v-model="ruleForm.articleNum" class="numList" size="small"
                                        placeholder="请选择">
                                        <el-option v-for="item in duringList" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                篇文章
                                <!-- <el-form-item  class="noLeft errorleft60" prop="wordsnum"  :rules="[ { required: true, message: '内容不能为空'}, { type: 'number', message: '内容必须为数字'} ]"  style="display: inline-block;"> -->
                                <el-form-item class="noLeft errorleft60" prop="wordsnum" style="display: inline-block;">

                                    <el-input class="num-input" type="wordsnum" v-model.number="ruleForm.wordsnum"
                                        size="small" placeholder="请填写整数字" clearable> </el-input>
                                </el-form-item>
                                万字。
                            </div>
                        </el-form-item>
                        <el-form-item label="出版单位" prop="publishName">
                            <el-input class="inputStyle" type="textarea" :rows="4" placeholder="多个单位用中文下的“、”或“；”隔开"
                                v-model="ruleForm.publishName"> </el-input>
                        </el-form-item>
                        <el-form-item label="所属学科" prop="pdate">
                            <el-select class="inputStyle" v-model="ruleForm.pdate" placeholder="请选择" size="small">
                                <el-option v-for="item in pdateList" :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否已进入CSSCI" prop="radioCssci">
                            <el-radio-group v-model="ruleForm.radioCssci">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="集刊中文介绍" prop="introduceCn">
                            <el-input class="inputStyle" type="textarea" :rows="4"
                                placeholder="包括集刊内容、集刊定位、研究领域、研究方法等，约200字" v-model="ruleForm.introduceCn"> </el-input>
                        </el-form-item>
                        <el-form-item label="集刊英文介绍" prop="introduceEn">
                            <el-input class="inputStyle" type="textarea" :rows="4"
                                placeholder="包括集刊内容、集刊定位、研究领域、研究方法等，约200字" v-model="ruleForm.introduceEn"> </el-input>
                        </el-form-item>
                        <el-form-item label="集刊中文关键词" prop="keywordCn">
                            <el-input class="inputStyle" v-model="ruleForm.keywordCn" size="small"
                                placeholder="请填写中文关键词" clearable> </el-input><span class="advise">(3~5个)</span>
                        </el-form-item>
                        <el-form-item label="集刊英文关键词" prop="keywordEn">
                            <el-input class="inputStyle" v-model="ruleForm.keywordEn" size="small"
                                placeholder="请填写英文关键词" clearable> </el-input><span class="advise">(3~5个)</span>
                        </el-form-item>
                        <div class="fstPblMsg">
                            <p>本集刊的价值及优劣势分析 <span>与本领域其他集刊与期刊的对比情况等</span></p>
                        </div>
                        <el-form-item label="出版本集刊的原因" prop="publishReason">
                            <el-input class="inputStyle" type="textarea" :rows="4" placeholder="请输入内容"
                                v-model="ruleForm.publishReason"> </el-input>
                        </el-form-item>
                        <el-form-item lable-width="0" class="noLeft">
                            <el-col :span="3">
                                <div class="title alignL"><span>*</span>本集刊能够为学界带来什么效果 </div>
                            </el-col>
                            <el-col :span="21">
                                <div class="itmsg">
                                    <el-form-item lable-width="0" class="noLeft" prop="publishEffect"
                                        :rules="[{required:true,message:'内容为必填'}]">
                                        <el-input class="inputStyle" type="textarea" :rows="4" placeholder="请输入内容"
                                            v-model="ruleForm.publishEffect"> </el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-form-item>
                        <el-form-item lable-width="0" class="noLeft">
                            <el-col :span="3">
                                <div class="title alignL"><span>*</span>该学科领域有哪些较具影响的期刊或集刊 </div>
                            </el-col>
                            <el-col :span="21">
                                <div class="itmsg">
                                    <el-form-item lable-width="0" class="noLeft" prop="otherPublish"
                                        :rules="[{required:true,message:'内容为必填'}]">
                                        <el-input class="inputStyle" type="textarea" :rows="4" placeholder="请输入内容"
                                            v-model="ruleForm.otherPublish"> </el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-form-item>
                        <el-form-item lable-width="0" class="noLeft">
                            <el-col :span="3">
                                <div class="title alignL"><span>*</span>本集刊与这些期刊或集刊有哪些不同 </div>
                            </el-col>
                            <el-col :span="21">
                                <div class="itmsg">
                                    <el-form-item lable-width="0" class="noLeft" prop="differenceOther"
                                        :rules="[{required:true,message:'内容为必填'}]">
                                        <el-input class="inputStyle" type="textarea" :rows="4" placeholder="请输入内容"
                                            v-model="ruleForm.differenceOther"> </el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-form-item>
                        <div class="fstPblMsg">
                            <p class="addtt">主办单位及简介<a class="fr" @click="addNew(1)">新增</a></p>
                        </div>
                        <el-form-item class="noLeft">
                            <div class="addBox" v-for="(item,index) in ruleForm.mianPublishList" :key="index">
                                <div class="del"><a @click="delNew(1,index)"
                                        v-if="ruleForm.mianPublishList.length>1">删除</a></div>
                                <el-form-item :label="'机构名称'+(index+1)" :prop="'mianPublishList.'+index+'.name'"
                                    :rules="{required: true, message: '姓名不能为空'}">
                                    <el-input class="inputStyle" v-model="item.name" size="small" placeholder="请填写机构名称"
                                        clearable> </el-input>
                                </el-form-item>
                                <el-form-item :label="'地址'+(index+1)" :prop="'mianPublishList.'+index+'.address'"
                                    :rules="{required: true, message: '地址不能为空'}">
                                    <el-input class="inputStyle" v-model="item.address" size="small"
                                        placeholder="请填写机构地址" clearable> </el-input>
                                </el-form-item>
                                <el-form-item :label="'简介'+(index+1)" :prop="'mianPublishList.'+index+'.description'">

                                    <el-input class="inputStyle" type="textarea" :rows="4" v-model="item.description"
                                        size="small" placeholder="请填写机构简介" clearable> </el-input>
                                </el-form-item>
                            </div>
                        </el-form-item>
                        <div class="fstPblMsg">
                            <p class="addtt">主编及简介<a class="fr" @click="addNew(2)">新增</a></p>
                        </div>
                        <el-form-item class="noLeft">
                            <div class="addBox" v-for="(item,index) in ruleForm.mainPerson" :key="index">
                                <div class="del"><a @click="delNew(2,index)" v-if="ruleForm.mainPerson.length>1">删除</a>
                                </div>
                                <el-form-item :label="'姓名'+(index+1)" :prop="'mainPerson.'+index+'.name'"
                                    :rules="rules.listmsg">
                                    <el-input class="inputStyle" v-model="item.name" size="small" placeholder="请填写姓名"
                                        clearable> </el-input>
                                </el-form-item>
                                <el-form-item :label="'职务、职称'+(index+1)" :prop="'mainPerson.'+index+'.position'">
                                    <el-input class="inputStyle" v-model="item.position" size="small"
                                        placeholder="请填写职务、职称" clearable> </el-input>
                                </el-form-item>
                                <el-form-item :label="'工作单位'+(index+1)" :prop="'mainPerson.'+index+'.unit'">
                                    <el-input class="inputStyle" v-model="item.unit" size="small" placeholder="请填写工作单位"
                                        clearable> </el-input>
                                </el-form-item>
                                <el-form-item :label="'电话'+(index+1)" :prop="'mainPerson.'+index+'.phoneNum'"
                                    :rules="rules.phoneNumber">
                                    <el-input class="inputStyle" v-model.number="item.phoneNum" size="small"
                                        placeholder="请填写电话" clearable> </el-input>
                                </el-form-item>
                                <el-form-item :label="'邮箱'+(index+1)" :prop="'mainPerson.'+index+'.email'"
                                    :rules="rules.emailNum">
                                    <el-input class="inputStyle" v-model="item.email" size="small" placeholder="请填写邮箱"
                                        clearable> </el-input>
                                </el-form-item>
                                <el-form-item :label="'简介'+(index+1)" :prop="'mainPerson.'+index+'.description'">
                                    <el-input class="inputStyle" type="textarea" :rows="4" v-model="item.description"
                                        size="small" placeholder="请填写内容" clearable> </el-input>
                                </el-form-item>
                            </div>
                        </el-form-item>
                        <div class="fstPblMsg">
                            <p class="addtt">编辑部联系人</p>
                        </div>
                        <el-form-item label="姓名" prop="contentname" :rules="[{required:'true',message:'内容为必填'}]">
                            <el-input class="inputStyle" v-model="ruleForm.contentname" size="small" placeholder="请填写姓名"
                                clearable> </el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="contentEmail"
                            :rules="[{required:'true',message:'内容为必填'}, { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}]">
                            <el-input class="inputStyle" v-model.email="ruleForm.contentEmail" size="small"
                                placeholder="请填写邮箱" clearable auto-complete="true"> </el-input>
                        </el-form-item>
                        <el-form-item label="电话" prop="contentPhone" :rules="rules.phoneNum">
                            <el-input class="inputStyle" v-model="ruleForm.contentPhone" size="small"
                                placeholder="请填写电话" clearable> </el-input>
                        </el-form-item>
                        <div class="fstPblMsg">
                            <p>学术委员会简介（或编辑委员会简介）</p>
                        </div>
                        <el-row :gutter="20" class="typeMsg">
                            <el-col :span="24">
                                <div class="itmsg">
                                    <el-form-item prop="xswyhjj" class="otherMsg">
                                        <el-input class="inputStyle w100" type="textarea" :rows="4" placeholder="请输入内容"
                                            v-model="ruleForm.xswyhjj"> </el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-row>
                        <!--<el-form-item label="电话" prop="xswyhjj">-->
                        <!--<el-input class="inputStyle w100" type="textarea" :rows="4"  placeholder="请输入内容" v-model="xswyhjj"> </el-input>-->
                        <!--</el-form-item>-->
                        <div class="fstPblMsg">
                            <p>其他需要补充说明的内容</p>
                        </div>
                        <el-row :gutter="20" class="typeMsg">
                            <el-col :span="24">
                                <div class="itmsg">
                                    <el-form-item prop="otherContent" class="otherMsg">
                                        <el-input class="inputStyle w100" type="textarea" :rows="4" placeholder="请输入内容"
                                            v-model="ruleForm.otherContent"> </el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-row>
                <div class="btns">
                    <a @click="submitTest">提交</a>
                </div>
        </div>
    <!-- </div> -->
</template>
<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
    data() {
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('手机号不能为空'))
            } else {
                const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
                if (reg.test(value)) {
                    callback()
                } else {
                    return callback(new Error('请输入正确的手机号'))
                }
            }
        }
        return {
            pageName: '集刊编号注册',
            pN: '',
            value: '',
            text: '',
            radioThpsz: '1',
            radioYn: '1',
            radioCnki: '1',
            radioCssci: '1',
            num: '',
            numList: [
                { name: '1', id: '1' },
                { name: '2', id: '2' },
                { name: '3', id: '3' },
                { name: '4', id: '4' },
                { name: '5', id: '5' }
            ],
            duringList: [
                { name: '8-10', id: '1' },
                { name: '11-14', id: '2' },
                { name: '15-20', id: '3' },
                { name: '21-25', id: '4' },
                { name: '26-30', id: '5' }
            ],
            pdate: '',
            pdateList: [],
            creatDate: '',
            pecent: '',
            pecentList: [
                { name: '10%', id: '0.1' },
                { name: '20%', id: '0.2' },
                { name: '30%', id: '0.3' },
                { name: '40%', id: '0.4' },
                { name: '50%', id: '0.5' }
            ],
            textarea: '',
            sourceMoney: '',
            sourceMoneyList: [
                { name: '国家基金', id: '1' },
                { name: '地方基金', id: '2' },
                { name: '财政补贴', id: '3' },
                { name: '院校资助', id: '4' },
                { name: '社会资助', id: '5' }
            ],
            fileList: [],
            labelPosition: 'right',
            ruleForm: {
                imageUrl: '',
                titleCn: '', //中文名
                titleEn: '',
                creatDate: '',
                num: '',
                publishName: '',
                articleNum: '', //每期文章数量
                wordsnum: '', //每期字数
                pdate: '',
                radioCssci: '',
                introduceCn: '', //中文介绍
                introduceEn: '',
                keywordCn: '',
                keywordEn: '',
                publishEffect: '', //影响
                publishReason: '', //出版原因
                otherPublish: '', //其他出版刊物
                differenceOther: '', //与其他刊物的不同
                mianPublishList: [{ name: '', address: '', introduce: '' }], //主要出版单位，
                mainPerson: [{ name: '', title: '', company: '', phoneNum: '', email: '', introduce: '' }],
                contentname: '',
                contentEmail: '',
                contentPhone: '',
                xswyhjj: '',
                otherContent: ''
            },
            imageUrl: '',
            titleCn: '', //中文名
            titleEn: '',
            publishName: '',
            articleNum: '', //每期文章数量
            wordsnum: '', //每期字数
            introduceCn: '', //中文介绍
            introduceEn: '',
            keywordCn: '',
            keywordEn: '',
            publishEffect: '', //影响
            publishReason: '', //出版原因
            otherPublish: '', //其他出版刊物
            differenceOther: '', //与其他刊物的不同
            mianPublishList: [{ name: '', address: '', description: '' }], //主要出版单位，
            mianPerson: [{ name: '', position: '', unit: '', phoneNum: '', email: '', description: '' }],
            exitorContent: { name: '', email: '', telephoneNum: '' },
            xswyhjj: '',
            otherContent: '',
            rules: {
                titleCn: [{ required: true, message: '请输入集刊中文名称', trigger: 'blur' }],
                creatDate: [{ type: 'date', required: true, message: '请选择创刊时间', trigger: 'change' }],
                num: [{ required: true, message: '请选择出版周期', trigger: 'change' }],
                articleNum: [{ required: true, message: '请选择每期篇数', trigger: 'change' }],
                wordsnum: [
                    { required: true, message: '请输入每期字数', trigger: 'blur' },
                    { type: 'number', message: '内容必须为数字', trigger: 'blur' }
                ],
                publishName: [{ required: true, message: '请输入出版单位', trigger: 'blur' }],
                pdate: [{ required: true, message: '请选择所属学科', trigger: 'change' }],
                introduceCn: [{ required: true, message: '请输入集刊中文介绍', trigger: 'blur' }],
                publishReason: [{ required: true, message: '请输入必填内容', trigger: 'blur' }],
                publishEffect: [{ required: true, message: '请输入必填内容', trigger: 'blur' }],
                otherPublish: [{ required: true, message: '请输入必填内容', trigger: 'blur' }],
                differenceOther: [{ required: true, message: '请输入必填内容', trigger: 'blur' }],
                contentname: [{ required: true, message: '请输入必填内容', trigger: 'blur' }],
                contactEmail: [
                    { required: true, message: '请输入必填内容', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱格式', trigger: ['blur', 'change'] }
                ],
                listmsg: [{ required: true, message: '请输入必填内容', trigger: 'blur' }],
                keywordCn: [{ required: true, message: '请输入中文关键词', trigger: 'blur' }],
                emailNum: [
                    { required: 'true', message: '内容为必填' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ],
                phoneNumber: [
                    { required: true, message: '电话号码必填', trigger: 'blur' },
                    { validator: checkPhone, trigger: 'blur' }
                ],
                phoneNum: [{ validator: checkPhone, trigger: 'blur' }]
            }
        }
    },
    components: {
        BreadCrumb
    },
    created() {
        this.getSubjectList()
    },
    methods: {
        ceshi() {
            this.$refs['ruleForm'].resetFields()
        },
        UploadUrl() {
            return '/admin/common/upload'
        },
        submitTest() {
            this.$refs['ruleForm'].validate(valid => {
                if (valid) {
                    // console.error('已校验')
                    this.submit()
                } else {
                    this.$alert('有必填信息未填写，确认后再提交', '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            // this.$message({
                            //     type: 'info',
                            //     message: ''
                            // });
                        }
                    })
                    return false
                }
            })
        },
        getSubjectList() {
            var _this = this
            _this.$http.get('/admin/api/cp/no/subject').then(res => {
                this.pdateList = res.data
            })
        },
        submit() {
            var _this = this
            // if(!this.titleCn) return;
            _this
                .$http({
                    method: 'post',
                    url: '/admin/api/cp/no/add',
                    data: {
                        titleCn: this.ruleForm.titleCn,
                        titleEn: this.ruleForm.titleEn,
                        cover: this.ruleForm.imageUrl,
                        firstDate: this.ruleForm.creatDate,
                        cycle: this.ruleForm.num, //出版周期
                        articleNo: this.ruleForm.articleNum,
                        words: this.ruleForm.wordsnum,
                        publisher: this.ruleForm.publishName,
                        subject: this.ruleForm.pdate, //所属学科
                        cssci: this.ruleForm.radioCssci,
                        descriptionCn: this.ruleForm.introduceCn,
                        descriptionEn: this.ruleForm.introduceEn,
                        keyWordCn: this.ruleForm.keywordCn,
                        keyWordEn: this.ruleForm.keywordEn,
                        reason: this.ruleForm.publishReason,
                        achievement: this.ruleForm.publishEffect, //成果
                        likePaper: this.ruleForm.otherPublish, //同类期刊
                        advantages: this.ruleForm.differenceOther, //优势
                        unitList: this.ruleForm.mianPublishList, //主办单位列表
                        authorList: this.ruleForm.mainPerson, //主编
                        contactName: this.ruleForm.contentname,
                        contactEmail: this.ruleForm.contentEmail,
                        contactPhone: this.ruleForm.contentPhone,
                        academicDescription: this.ruleForm.xswyhjj,
                        remark: this.ruleForm.otherContent
                    }
                })
                .then(res => {
                    if (res.data.code == 0) {
                        this.$alert('已成功提交申请表', '提示', {
                            confirmButtonText: '确定',
                            callback: action => {
                                // this.$message({
                                //     type: 'info',
                                //     message: `action: ${ action }`
                                // });
                            }
                        })
                        _this.$refs['ruleForm'].resetFields()
                    }
                })
        },
        handleAvatarSuccess(res, file) {
            this.ruleForm.imageUrl = URL.createObjectURL(file.raw)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg'
            const isPNG = file.type === 'image/png'
        },
        delNew(type, index) {
            if (type == 1) {
                this.ruleForm.mianPublishList.splice(index, 1)
                // this.ruleForm.mianPublishList.pop({name:'',address:'',description:''})
            } else {
                this.ruleForm.mainPerson.splice(index, 1)
                // this.ruleForm.mainPerson.pop({name:'',position:'',unit:'',phoneNum:'',email:'',description:''})
            }
        },
        addNew(type) {
            if (type == 1) {
                this.ruleForm.mianPublishList.push({ name: '', address: '', description: '' })
            } else {
                this.ruleForm.mainPerson.push({ name: '', position: '', unit: '', phoneNum: '', email: '', description: '' })
            }
        }
    }
}
</script>
<style scoped>
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.braed {
    margin: 15px 0;
}
.braed >>> .el-breadcrumb__inner.is-link:hover {
    color: #333;
    cursor: pointer;
}
.pageTitle {
    height: 60px;
    font-size: 16px;
    line-height: 60px;
    text-align: center;
    font-weight: 600;
    background: rgba(189, 26, 45, 0.1);
    /* background: rgba(196, 227, 230, 0.7); */
}
.tip {
    width: 100%;
    color: #606266;
    font-size: 12px;
    padding: 8px 8px;
    margin-bottom: 12px;
}
.tip span {
    color: rgba(189, 26, 45, 1);
}
.tip > p {
    font-size: 14px;
    line-height: 18px;
}
.typeMsg {
    margin-bottom: 16px;
}
.inputStyle {
    display: inline-block;
    width: 420px;
}
.inputStyle.w100 {
    width: 570px;
}
.title {
    text-align: right;
    line-height: 32px;
}
.title.alignL {
    text-align: left;
    line-height: 18px;
}
.title span {
    color: rgba(189, 26, 45, 1);
}
.itmsg {
    line-height: 40px;
}
.itmsg .numList {
    width: 100px;
}
.itmsg .numList >>> input,
.itmsg .num-input >>> input {
    padding: 0 10px;
}
.itmsg .num-input {
    display: inline-block;
    width: 100px;
    text-align: center;
}
.itmsg .mult-input {
    width: 500px;
}
.fstPblMsg {
    font-size: 16px;
    line-height: 42px;
    padding: 0 15px;
    font-weight: bold;
    background: #f9f9f9;
    margin-bottom: 16px;
}
.fstPblMsg .addtt {
    padding: 7px 0;
}
.fstPblMsg span {
    font-weight: normal;
    font-size: 12px;
    margin-left: 16px;
}
.advise {
    color: #606266;
    font-size: 12px;
}
.addtt {
    width: 580px;
    line-height: 30px;
    padding-left: 76px;
    margin-bottom: 10px;
}
.addtt span {
    font-weight: bold;
}
.addtt a {
    padding: 0 6px;
    /*width: 66px;*/
    color: #fff;
    text-align: center;
    border-radius: 4px;
    background: rgba(189, 26, 45, 1);
    /* #4db6be; */
    font-weight: normal;
    cursor: pointer;
}
.btns {
    width: 580px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 80px;
}
.btns a {
    display: inline-block;
    width: 80px;
    padding: 8px 0;
    margin: 0 8px;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    background: rgba(189, 26, 45, 1);
    cursor: pointer;
}
.avatar-uploader {
    width: 170px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: rgba(189, 26, 45, 0.1);
    /* #4db6be; */
}
>>> .el-radio__input.is-checked .el-radio__inner {
    border-color: rgba(189, 26, 45, 0.1);
    background: rgba(189, 26, 45, 0.1);
}
>>> .el-radio__input.is-checked + .el-radio__label {
    color: rgba(189, 26, 45, 0.1);
    /* #4db6be; */
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 170px;
    height: 220px;
    display: block;
}
.noLeft >>> .el-form-item__content {
    margin-left: 0 !important;
}
.noLeft >>> .el-col-21 {
    padding-left: 2px;
}
.noLeft .el-form-item >>> .el-form-item__error,
.noLeft >>> .el-form-item__content .el-form-item__error {
    left: 152px;
}
.noLeft.errorleft60 >>> .el-form-item__content .el-form-item__error {
    left: 10px;
}
.el-form-item .el-form-item {
    margin-bottom: 22px !important;
}
.otherMsg >>> .el-form-item__content {
    margin-left: 0 !important;
}
.addBox {
    position: relative;
}
.del {
    position: absolute;
    top: 3px;
    left: 590px;
    z-index: 3;
}
.del > a {
    float: right;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    padding: 0 6px;
    color: rgba(189, 26, 45, 1);
    text-align: center;
    border-radius: 4px;
    font-weight: 400;
    cursor: pointer;
}
</style>
